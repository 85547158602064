h1 {
    color: rgb(246, 255, 255);
    font-family: palatino, sans-serif;
    margin-top: 20%;
    margin-bottom: 20%;
    font-size: 4em;
    z-index: 100;
    text-shadow: 2px 2px 4px #000000;
}

h2 {
    color: rgb(246, 255, 255);
    font-family: Sistina Platino, sans-serif;
    font-size: 1.5em;
    z-index: 100;
}

#textbox {
    width: 550px; 
    word-wrap: break-word; 
    margin: 0 auto; 
    margin-top: 1.5em;
    text-align: center; 
}

.typewriter-container {
    width: 50%; 
    max-height: 66vh; 
    word-wrap: break-word; 
    margin: 0 auto; 
    margin-top: 1.5em;
    text-align: left; 
    color: rgb(246, 255, 255);
    font-family: "Courier New", Courier, monospace;
    font-size: 1em;
    z-index: 100;
    overflow-y: auto;
}

.typewriter-container::-webkit-scrollbar {
    display: none;
}

#conversation {
    float: right;
    margin-right: 10px; /* Adjusts the margin for the button */
}

.endText {
    font-family: "Courier New", Courier, monospace;
    top: 25%;
    color: rgb(246, 255, 255);
    font-size: 3.5em;
    z-index: 999
}
