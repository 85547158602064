body {
  text-align: center;
  background-color: #14181C;
  margin: 0;
  padding: 0;
}

.stripe {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 1.5em;
  opacity: 75%;
  z-index: -1;
}

#orange {
  left: 15%;
  background-color: #FA7F00;
}

#green {
  left: calc(15% + 1.5em); 
  background-color: #1BE155;
}

#blue {
  left: calc(15% + 3em); 
  background-color: #41BDF4;
}

