img {
    width: 20%;
    min-height: 10%;
    max-width: 130px;
    height: auto;
    max-height: 200px;  
    margin: 10px;
    border-radius: 10px;
    box-shadow: 4px 4px;
    flex: 0 0 auto; 

}

.original-poster {
    position: absolute;
    left: 0;           
    right: 0;              
    bottom: 0;             
    padding: 10px 20px;
    overflow: hidden;      
    display: flex;     
    justify-content: center; 
}

.image-container {
    position: absolute;
    bottom: 0;             
    left: 0;
    right: 0;
    padding: 10px 20px;
    overflow: hidden;      
    display: flex;     
    white-space: nowrap;   
    justify-content: flex-start; 
}

.image-container img.highlight {
    animation: scaleUp 2s forwards;
}

@keyframes scaleUp {
    from { transform: scale(1); }
    to { transform: scale(1.1); }
}

.image-container.full-screen {
    position: fixed;  /* Changed from absolute to fixed */
    top: 0;           /* Added to expand to the full screen */
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0;       /* Optional: Adjust padding if needed */
    background-color: rgba(0, 0, 0, 0.8); /* Optional: Add a background color for better visibility */
    justify-content: center; /* Center the images horizontally */
    align-items: center;     /* Center the images vertically */
    transition: all 0.5s ease-in-out; /* Smooth transition for the expansion */
}



