.animate-input {
    animation: slideDown 0.5s ease-out forwards;
}

@keyframes slideDown {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.fadeOut {
    animation: fadeOut 0.5s forwards;
}

@keyframes fadeOut {
    from {
        opacity: 1;
        transform: translateY(0px);
    }
    to {
        opacity: 0;
        transform: translateY(-20px);
    }
}


.animate-input.fade-out {
    animation: fadeOut 0.5s ease forwards;
}


button {
    margin-top: 60px;
    background-color: #14b444;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    border-radius: 6px;
    font-size: 16px;
    font-family: 'Sistina Platino', sans-serif;
    box-shadow: 2px 2px 4px #000000;
    transition: background-color 0.3s;
}

button:hover {
    background-color: #119037;
    cursor: pointer;
}

input[type=text] {
    width: 25%;
    max-width: 300px;
    padding: 12px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    border: 1px solid #ccc; 
    border-radius: 4px; 
    color: #333; 
    transition: border-color 0.3s; 
}

input[type=text]:focus {
    border-color: #14b444; 
}
